<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        :meta="meta"
        :page-title="moduleConfig.page_title"
        :page-image="moduleConfig.page_image"
        :page-kv-image="moduleConfig.page_kv_image"
        :header-section="moduleConfig.header_section"
      />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";
import Header from "@/components/Page/Liff/Shared/HeaderV3";

export default {
  mixins: [themeColor],
  components: { Header },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_news").module_config || {};
    },
  },
  data() {
    return {
      themeConfigPage: "news",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  --s-primary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #595959;
  --s-gray: #909399;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #ecedef;
}

.s-layout {
  color: var(--liff-primary_text_color) !important;
  background-color: var(--liff-layout_bg_color) !important;
  min-height: 100dvh;
  padding-bottom: 1rem;
}

.s-container {
  padding-bottom: 1.5rem;
}

.content {
  background: var(--liff-content_bg_color);
}
</style>
